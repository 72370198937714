import React, { useState, useEffect } from 'react'
import { Link as Link2, useHistory } from 'react-router-dom'
import { navigate } from '@reach/router'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Cookies from 'js-cookie'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import axios from 'axios'

import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_SHOPCONFIG } from './components/graphql/config'

const GET_DARKMODE = gql`
  {
    isDarkModeEnabled @client
  }
`

const restApi = 'http://frapp.cloud:3501'

const url = 'http://frapp.cloud:3501/users/authenticate'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 48px)',
    backgroundColor: 'white',
  },
  image: {
    backgroundImage: 'url(http://soonfatt.frapp.cloud/images/sfnoodles.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const redirectUrl = [
  { access: 'Management', url: '/sales' },
  { access: 'Marketing', url: '/sales' },
  { access: 'Sales', url: '/sales' },
  { access: 'Operation', url: '/routing' },
  { access: 'Customer Service', url: '/despatching' },
  { access: 'Accounts', url: '/despatching' },
  { access: 'Booking Agent', url: '/routing' },
  { access: 'Pickup', url: '/routing' },
]

export default function SignInSide(props) {
  const classes = useStyles()
  const history = useHistory()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.frapp.cloud')
      ? window.location.host.split('.frapp.cloud')[0]
      : ''
  )
  // const [dbhost, setDbhost] = useState('shop')

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const { data: { loginDetails } = { loginDetails: {} }, client } = useQuery(
    GET_DARKMODE
  )

  useEffect(() => {
    if (Cookies.get('signedin')) {
      props.signedIn(true)
      props.history.push('/')
    }
  }, [])

  const submitForm = (event) => {
    event.preventDefault()

    const options = {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: `username=${username}&password=${password}&client=${dbhost}`,
    }

    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            alert('Username not found, please retry')
          }
          if (response.status === 401) {
            alert('Username and password do not match, please retry')
          }
          if (response.status === 500) {
            alert('Username and password do not match, please retry')
          }
        }
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        client.writeData({ data: { loginDetails: data } })

        if (data.token) {
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${data.token}`

          const numWeeks = 1
          const expiration_date = new Date()
          expiration_date.setDate(expiration_date.getDate() + numWeeks * 7)

          document.cookie =
            'signedin=true; expires=' + expiration_date.toUTCString()
          document.cookie =
            `token=${data.token}; expires=` + expiration_date.toUTCString()
          document.cookie =
            `StaffID=${data.StaffID}; expires=` + expiration_date.toUTCString()
          document.cookie =
            `Department=${data.Department}; expires=` +
            expiration_date.toUTCString()

          /* document.cookie = 'signedin=true'
          document.cookie = `token=${data.token}`
          document.cookie = `StaffID=${data.StaffID}`
          document.cookie = `Department=${data.Department}` */

          //console.log(props)
          //navigate('/private-area')
          props.signedIn(true)
          props.history.push('/')
        }
      })
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} elevation={6}>
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}

          <Hidden only={['xs', 'sm']}>
            <div style={{ width: 250, textAlign: 'center' }}>
              <img
                style={{ width: 100, paddingTop: 30, paddingBottom: 20 }}
                src={`${restApi}/media/${dbhost}/uploads/logo/${shopconfig.COMLOGO}`}
              />
              <Typography variant="body2" color="textSecondary" align="center">
                {shopconfig.COMSHO}
              </Typography>
            </div>
          </Hidden>

          <form onSubmit={submitForm} className={classes.form} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Mobile"
              name="email"
              autoComplete="off"
              autoFocus
              onChange={(event) => setUsername(event.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="off"
              onChange={(event) => setPassword(event.target.value)}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              variant="contained"
              disableElevation
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>

            <br />
            <div style={{ textAlign: 'center' }}>
              <Button
                className={classes.button}
                onClick={() => {
                  scrollToTop()
                  history.push('/customer')
                }}
              >
                Create account
              </Button>
            </div>
            <div style={{ marginTop: 30, textAlign: 'left' }}>
              <Button
                className={classes.button}
                onClick={() => {
                  scrollToTop()
                  history.push('/')
                }}
                startIcon={<ArrowBackIcon />}
              >
                Back to shopping
              </Button>
            </div>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}
