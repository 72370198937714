import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  UPDATE_ORDER,
  GET_ORDERS,
  CREATE_ORDER,
  REMOVE_ORDER,
  GET_ORDERDETAILS,
} from '../graphql/order'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import BookingCancelDialog from './orderCancelDialog'
import Cookies from 'js-cookie'
import TextFieldDisplay from '../common/textFieldDisplay'
import BookingDetailsItem from './orderDetailsItem'

const restApi = 'http://frapp.cloud:3501'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    background: '#efefef',
    overflowY: 'visible',
  },
  dialogTitle: {
    paddingLeft: theme.spacing(2),
    background: theme.palette.primary.main,
    height: 64,
    color: theme.palette.primary.contrastText,
  },
  dialogContent: {
    padding: theme.spacing(2),
    background: '#efefef',
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[50],
  },
  dialogActions: {
    background: theme.palette.primary.paper,
    padding: theme.spacing(2),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const updateCache = (cache, { data }) => {
  const old = cache.readQuery({
    query: GET_ORDERS,
  })
  const latest = data.updateorder
  const foundIndex = old.orders.findIndex((item) => item.id === latest.id)
  old.orders.splice(foundIndex, 1, latest)
  cache.writeQuery({
    query: GET_ORDERS,
    data: { orders: [...old.orders] },
  })
}

// START
function BookingDialog(props) {
  const classes = useStyles()

  const {
    loading: loadingOrderDetails,
    data: { orderdetails } = { orderdetails: [] },
  } = useQuery(GET_ORDERDETAILS, {
    variables: { OrderID: props.data.id },
    skip: !props.data,
  })

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    const latest = data.createorder
    cache.writeQuery({
      query: GET_ORDERS,
      data: { orders: [latest, ...old.orders] },
    })
  }

  const [createOrder] = useMutation(CREATE_ORDER, { update: addCache })
  const [updateOrder] = useMutation(UPDATE_ORDER, {
    update: updateCache,
  })

  const [customerName, setCustomerName] = useState()
  const [customer, setCustomer] = useState()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirm, setConfirm] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)

  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    if (data.removeorder) {
      const latest = old.orders.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_ORDERS,
        data: { orders: [...latest] },
      })
      props.setSelected([])
      props.setOrder()
    }
  }

  const [removeOrder] = useMutation(REMOVE_ORDER, {
    update: deleteCache,
  })

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updateOrder({
        variables: {
          id: props.data.id,
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } else {
      createOrder({
        variables: {
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    }

    props.setSelected([])
    props.setOrder()
    props.setOpen(false)
  }

  const handleClickDelete = () => {
    removeOrder({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
    props.setOpen(false)
    props.setSelected([])
    props.setOrder()
  }

  useEffect(() => {
    if (orderdetails.length == 0) return

    const totalAmount = orderdetails.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [orderdetails])

  useEffect(() => {
    setFinalAmount(props.data && props.data.DeliveryCharges + totalAmount)
  }, [props.data, totalAmount])

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Order?"
        okButton="Yes"
        title="Continue remove"
      />
      <BookingCancelDialog
        openCancel={openCancel}
        setOpenCancel={setOpenCancel}
        data={props.data}
        setOpen={props.setOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
          props.setSelected([])
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="order-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="order-dialog">
            Order {props.data.OrderNo}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
                props.setSelected([])
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid style={{ paddingTop: 0 }} item md={4} xs={4}>
                  <TextFieldDisplay
                    label="Total"
                    value={`RM ${parseFloat(totalAmount).toFixed(2)}`}
                  />
                </Grid>
                <Grid style={{ paddingTop: 0 }} item md={4} xs={4}>
                  <TextFieldDisplay
                    label="Delivery"
                    value={`RM ${parseFloat(props.data.DeliveryCharges).toFixed(
                      2
                    )}`}
                  />
                </Grid>
                <Grid style={{ paddingTop: 0 }} item md={4} xs={4}>
                  <TextFieldDisplay
                    label="Order Total"
                    value={`RM ${parseFloat(finalAmount).toFixed(2)}`}
                  />
                </Grid>
              </Grid>

              <Divider classes={{ root: classes.dividerroot }} />
              <br />
              <br />

              <Grid container spacing={3}>
                <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                  <TextFieldDisplay
                    label="First Name"
                    value={props.data.FirstName}
                  />

                  <TextFieldDisplay
                    label="Last Name"
                    value={props.data.LastName}
                  />

                  <TextFieldDisplay label="Phone" value={props.data.Phone} />

                  <TextFieldDisplay label="Email" value={props.data.Email} />
                </Grid>
                <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                  <TextFieldDisplay
                    label="Address 1"
                    value={props.data.Address1}
                  />

                  <TextFieldDisplay
                    label="Address 2"
                    value={props.data.Address2}
                  />

                  <TextFieldDisplay label="City" value={props.data.City} />

                  <TextFieldDisplay
                    label="Postcode"
                    value={props.data.PostCode}
                  />

                  <TextFieldDisplay label="State" value={props.data.State} />
                </Grid>
              </Grid>

              <Divider classes={{ root: classes.dividerroot }} />

              {props.data &&
                orderdetails.map((order, i) => (
                  <BookingDetailsItem key={i} data={order} />
                ))}
              {/* {JSON.stringify(props.data, 0, 0, 4)} */}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {/* {props.data && (
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={() => {
                    setConfirm(true)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )} */}
              {props.data && (
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={() => {
                    setOpenCancel(true)
                  }}
                >
                  Cancel Order
                </Button>
              )}
              {/*  <Button variant="contained" color="primary" type="submit">
                Save
              </Button> */}
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}

export default BookingDialog
