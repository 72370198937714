import React, { useState, useEffect } from 'react'
import {
  Redirect,
  Route,
  BrowserRouter,
  Switch,
  withRouter,
  Link,
  Link as Link2,
} from 'react-router-dom'

import clsx from 'clsx'
import Cookies from 'js-cookie'
import Login from './Login'
import PrivateArea from './PrivateArea'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBox,
  faUserTie,
  faTruck,
  faPlane,
  faFileInvoiceDollar,
  faCog,
  faCalculator,
  faPowerOff,
  faFileAlt,
  faFolderOpen,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'
import './App.css'

import {
  fade,
  makeStyles,
  useTheme,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import IconButton from '@material-ui/core/IconButton'
import FacebookIcon from '@material-ui/icons/Facebook'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import ScheduleIcon from '@material-ui/icons/Schedule'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'

import AccountCircle from '@material-ui/icons/AccountCircle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { GET_SHOPCONFIG } from './components/graphql/config'
import { GET_BUYER } from './components/graphql/buyer'
import { SnackbarProvider } from 'notistack'
import Logout from './Logout'
import Shop from './components/shop/shop'
import ShopDetails from './components/shop/shopDetails'
import Checkout from './components/shop/checkout'
import Customer from './components/shop/customer'
import MyOrder from './components/shop/myorders'
import Terms from './components/shop/terms'
import Faq from './components/shop/faq'
import BottomNav from './components/shop/bottomNav'
import MobileCart from './components/shop/mobiecart'
import CartButton from './components/shop/cartButton'
import OrderDetail from './components/shop/orderdetail'
import Admin from './components/admin/admin'
import Home from './components/onepirate/Home'
import SignUp from './components/onepirate/SignUp'
import Register from './components/onepirate/Register'

const GET_DARKMODE = gql`
  {
    isDarkModeEnabled @client
  }
`
const drawerWidth = 240

const restApi = 'http://frapp.cloud:3501'

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    flexGrow: 1,
    padding: 0,
    textAlign: 'center',
    lineHeight: 1,
  },
  toolBar2: {
    margin: 'auto',
    maxWidth: 1199,
    width: '100%',
    minHeight: 48,
    paddingRight: 0,
    paddingLeft: 0,
    backgroundColor: 'white',
    color: (props) => props.COLORPRIMARY,
    [theme.breakpoints.up('md')]: {
      minHeight: 30,
      backgroundColor: 'transparent',
      color: 'white',
    },
  },
  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  link: { color: (props) => props.COLORPRIMARY, textDecoration: 'none' },
  inline: {
    display: 'inline',
    color: (props) => props.COLORPRIMARY,
  },
  drawer: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
  },
  drawerOpen: {
    background: '#cfd8dc!important',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: '#cfd8dc!important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    padding: theme.spacing(0, 0, 0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  appbarroot: {
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      position: 'static',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    marginTop: 48,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    } /* 
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7) + 1,
    }, */,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  icon: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  iconList: {
    color: '#757575',
    minWidth: '26px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  menuItem: {
    paddingLeft: 10,
    paddingRight: 10,
    textDecoration: 'none',
    fontWeight: 'bold',
    color: 'white',
  },
  signinMenu: {
    paddingRight: 10,
    textDecoration: 'none',
    fontWeight: 'bold',
    color: (props) => props.COLORPRIMARY,
    [theme.breakpoints.up('sm')]: {
      color: 'white',
    },
  },
  menuDivider: {
    height: 30,
    borderLeft: '1px solid rgba(255, 255, 255, 0)',
  },
  listitemroot: {
    minWidth: 250,
  },
}))

export default function App(props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [signedIn, setSignedIn] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [redirectTo, setRedirectTo] = useState()

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: [] },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const {
    loading: loadingBuyer,
    data: { buyer } = { buyer: { id: null } },
  } = useQuery(GET_BUYER, { skip: !Cookies.get('signedin') })

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const [open, setOpen] = useState(false)

  const [theme, setTheme] = useState({
    palette: {
      primary: {
        dark: '#fff',
        main: '#ff5722',
        paper: '#efefef',
        contrastText: '#fff',
      },
      secondary: {
        main: '#fff',
      },
      background: {
        default: '#efefef',
      },
      type: 'light', // Switching the dark mode on is a single property value change.
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    transitions: {
      duration: {
        shortest: 20,
        shorter: 60,
        short: 100,
        standard: 100,
        complex: 175,
        enteringScreen: 125,
        leavingScreen: 95,
      },
    },
  })

  const classes = useStyles(shopconfig)

  const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.frapp.cloud')
      ? window.location.host.split('.frapp.cloud')[0]
      : ''
  )
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open2 = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    console.log(dbhost)
  }, [])

  const theme2 = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [dense, setDense] = React.useState(false)

  const {
    data: { isDarkModeEnabled, loginDetails } = {
      isDarkModeEnabled: false,
    },
    client,
  } = useQuery(GET_DARKMODE)

  const handleToggle = () => {
    toggleDarkTheme(!isDarkModeEnabled)
    setDense(!isDarkModeEnabled)
    client.writeData({ data: { isDarkModeEnabled: !isDarkModeEnabled } })
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  const sideList = (side) => (
    <div>
      <div>
        <IconButton onClick={handleDrawerClose}>
          {theme2.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider className={classes.divider} />
      <List>
        {menus.map((menu, index) => {
          const access = menu.access.find(
            (access) => access == Cookies.get('Department')
          )
          if (access)
            return (
              <Link className={classes.link} to={menu.link} key={index}>
                <ListItem button key={index}>
                  <Tooltip title={menu.title}>
                    <ListItemIcon className={classes.iconList}>
                      {menu.icon}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={menu.title} />
                </ListItem>
              </Link>
            )
        })}
      </List>
      <Divider className={classes.divider} />
      <List>
        <Link
          to={`/logout`}
          style={{ color: '#37474f', textDecoration: 'none' }}
        >
          <ListItem button key={1001}>
            <ListItemIcon className={classes.iconList}>
              <FontAwesomeIcon icon={faPowerOff} style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Link>
      </List>
      <Divider className={classes.divider} />
    </div>
  )

  // we change the palette type of the theme in state
  const toggleDarkTheme = (isDarkTheme) => {
    if (isDarkTheme) {
      setTheme({
        palette: {
          primary: {
            main: shopconfig.COLORPRIMARY,
            paper: '#323232',
            contrastText: '#000',
          },
          secondary: {
            main: shopconfig.COLORPRIMARY,
          },
          background: {
            default: '#212121',
          },
          type: 'dark', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    } else {
      setTheme({
        palette: {
          primary: {
            dark: shopconfig.COLORPRIMARY,
            main: shopconfig.COLORPRIMARY,
            paper: '#f5f5f5',
            contrastText: '#f3f3f3',
          },
          secondary: {
            main: shopconfig.COLORPRIMARY,
          },
          background: {
            default: '#efefef',
          },
          type: 'light', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    }
  }

  const muiTheme = createMuiTheme(theme)

  const handleSignedIn = (link) => {
    setSignedIn(true)
    setRedirect(true)
    setRedirectTo(link)
  }

  const renderRedirect = () => {
    if (Cookies.get('signup')) {
      return <Redirect to="/register" />
    }
  }

  const menus = []

  return (
    <SnackbarProvider>
      <BrowserRouter>
        {renderRedirect()}
        <CssBaseline />

        <Switch>
          <Route exact path="/" render={() => <Home />} />
          <Route path="/signup" component={SignUp}></Route>
          <Route path="/register" component={Register}></Route>
          <Route
            path="/login"
            render={(a) => <Login {...a} signedIn={setSignedIn} />}
          />
          <Route
            path="/logout"
            render={(a) => <Logout signedIn={setSignedIn} />}
          />
          <Route path="/shop/:id" component={ShopDetails}></Route>
          <Route path="/customer" component={Customer}></Route>
          <Route path="/checkout" component={Checkout}></Route>
          <Route path="/terms" component={Terms}></Route>
          <Route path="/faq" component={Faq}></Route>
          <Route path="/order/detail/:OrderNo" component={OrderDetail}></Route>
          <Route exact path="/order" component={MyOrder}></Route>
          <Route path="/mobilecart" component={MobileCart}></Route>
          <Route
            path="/admin"
            render={(a) => <Admin {...a} setTheme={setTheme} />}
          ></Route>
        </Switch>
      </BrowserRouter>
    </SnackbarProvider>
  )
}
