import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useForm from 'react-hook-form'
import { useSnackbar } from 'notistack'
import TextField from '@material-ui/core/TextField'
import TextFieldDisplay from '../common/textFieldDisplay'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_ORDERS, UPDATE_ZONE } from '../graphql/order'
import Cookies from 'js-cookie'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    background: '#efefef',
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    height: 64,
    color: theme.palette.primary.contrastText,
  },
  dialogContent: {
    background: '#efefef',
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[50],
  },
  dialogActions: {
    background: theme.palette.primary.paper,
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
}))

// START Cancelled
export default function BookingCancelDialog(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { handleSubmit, register, errors } = useForm()

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    const latest = data.updateZone
    const foundIndex = old.orders.findIndex((item) => item.id === latest.id)
    old.orders.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_ORDERS,
      data: { orders: [...old.orders] },
    })
  }

  const [updateZone] = useMutation(UPDATE_ZONE, { update: updateCache })

  const onSubmit = (values) => {
    console.log(values)

    updateZone({
      variables: {
        id: props.data.id,
        ZoneID: props.data.ZoneID,
        Status: 'Cancelled',
        CreatedBy: Cookies.get('StaffID'),
        StatusText: values.Reason,
      },
    })
    props.setOpen(false)
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.openCancel}
        onClose={() => {
          props.setOpenCancel(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="billingdetails-dialog"
      >
        <React.Fragment>
          <DialogTitle
            className={classes.dialogTitle}
            id="billingdetails-dialog"
          >
            Cancel Order
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpenCancel(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid style={{ paddingBottom: 0 }} item md={6} xs={12}>
                  <TextFieldDisplay
                    label="Order No"
                    value={props.data && props.data.OrderNo}
                  />
                  <TextField
                    name="Reason"
                    multiline
                    label="Reason of Cancel"
                    margin="dense"
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.Reason}
                    helperText={errors.Reason && errors.Reason.message}
                  />
                </Grid>
              </Grid>
              {/* 
              <pre>{JSON.stringify(props, null, 4)}</pre> */}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
