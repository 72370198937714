import withRoot from './modules/withRoot'
// --- Post bootstrap -----
import React, { useState } from 'react'
import { fade, makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { useHistory } from 'react-router-dom'
import { Field, Form, FormSpy } from 'react-final-form'
import Typography from './modules/components/Typography'
import AppFooter from './modules/views/AppFooter'
import AppAppBar from './modules/views/AppAppBar'
import AppForm from './modules/views/AppForm'
import { email, required } from './modules/form/validation'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import { useSnackbar } from 'notistack'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'

import useForm from 'react-hook-form'

const url = 'http://frapp.cloud:3501/users/signup'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  paperroot: {
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
  },
  toolbar: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  dividerRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  margin: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  containerroot: {
    paddingTop: 0,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  margin2: {
    width: '100%',
    marginTop: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #00b8d4',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.secondary.main,
    },
  },
}))

function SignUp() {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [sent, setSent] = React.useState(false)
  const { handleSubmit, register, errors } = useForm()

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [shopname, setShopname] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postCode, setPostCode] = useState('')
  const [country, setCountry] = useState('Malaysia')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const onSubmit = (values) => {
    console.log(values)
    const options = {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: `phone=${phone}&password=${password}&email=${email}&firstName=${firstName}&lastName=${lastName}&shopname=${shopname}`,
    }

    fetch(url, options)
      .then((response) => {
        /* if (!response.ok) {
          if (response.status === 404) {
            alert('Username not found, please retry')
          }
          if (response.status === 401) {
            alert('Username and password do not match, please retry')
          }
          if (response.status === 500) {
            alert('Username and password do not match, please retry')
          }
        } */
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.message == 'Insert Completed') {
          const numWeeks = 1
          const expiration_date = new Date()
          expiration_date.setDate(expiration_date.getDate() + numWeeks * 7)

          document.cookie =
            'signup=true; expires=' + expiration_date.toUTCString()

          document.cookie =
            `shopname=${shopname}; expires=` + expiration_date.toUTCString()

          history.push('/register')
        } else {
          enqueueSnackbar(data.message, {
            variant: 'error',
          })
        }
      })

    setSent(true)
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign Up
          </Typography>
          <Typography variant="body2" align="center">
            Create your new ecommerce store now. It's <b>FREE</b>.
          </Typography>
          <br />
        </React.Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl error={errors.Username && true}>
                <InputLabel shrink htmlFor="Username">
                  Mobile
                </InputLabel>
                <InputBase
                  value={phone}
                  classes={{
                    root: classes.rootBootstrap,
                    input: classes.inputBootstrap,
                  }}
                  id="Username"
                  name="Username"
                  onChange={(e) => {
                    setPhone(e.target.value.replace(/[^0-9]/g, ''))
                  }}
                  placeholder="eg.0127778888"
                  fullWidth
                  margin="normal"
                  autoComplete="off"
                  inputProps={{
                    maxLength: 200,
                  }}
                  inputRef={register({
                    required: 'This field is Required',
                  })}
                  startAdornment={
                    <InputAdornment position="start">
                      <WhatsAppIcon
                        fontSize="large"
                        style={{ color: '#4FCE5D' }}
                      />
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {errors.Username && errors.Username.message}
                  {!errors.Username && (
                    <React.Fragment>
                      Please provide a <b>WhatsApp</b> mobile no
                    </React.Fragment>
                  )}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                error={errors.Password && true}
                style={{ marginTop: 0 }}
                className={classes.margin}
              >
                <InputLabel shrink htmlFor="Password">
                  Password
                </InputLabel>
                <InputBase
                  classes={{
                    root: classes.rootBootstrap,
                    input: classes.inputBootstrap,
                  }}
                  id="Password"
                  name="Password"
                  fullWidth
                  margin="normal"
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  autoComplete="off"
                  inputProps={{
                    maxLength: 200,
                    type: 'password',
                  }}
                  inputRef={register({})}
                />
                <FormHelperText>
                  {errors.Password && errors.Password.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Hidden only={['xs', 'sm']}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Divider classes={{ root: classes.dividerRoot }} />
              </Grid>
            </Grid>
          </Hidden>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl
                error={errors.FirstName && true}
                style={{ marginTop: 0 }}
                className={classes.margin}
              >
                <InputLabel shrink htmlFor="FirstName">
                  First Name
                </InputLabel>
                <InputBase
                  value={firstName}
                  classes={{
                    root: classes.rootBootstrap,
                    input: classes.inputBootstrap,
                  }}
                  id="FirstName"
                  name="FirstName"
                  fullWidth
                  onChange={(e) => {
                    setFirstName(e.target.value)
                  }}
                  margin="normal"
                  autoComplete="off"
                  inputProps={{
                    maxLength: 200,
                  }}
                  inputRef={register({
                    required: 'This field is Required',
                  })}
                />
                <FormHelperText>
                  {errors.FirstName && errors.FirstName.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                error={errors.LastName && true}
                style={{ marginTop: 0 }}
                className={classes.margin}
              >
                <InputLabel shrink htmlFor="LastName">
                  Last Name
                </InputLabel>
                <InputBase
                  value={lastName}
                  classes={{
                    root: classes.rootBootstrap,
                    input: classes.inputBootstrap,
                  }}
                  id="LastName"
                  name="LastName"
                  onChange={(e) => {
                    setLastName(e.target.value)
                  }}
                  fullWidth
                  margin="normal"
                  autoComplete="off"
                  inputProps={{
                    maxLength: 200,
                  }}
                  inputRef={register({
                    required: 'This field is Required',
                  })}
                />
                <FormHelperText>
                  {errors.LastName && errors.LastName.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl
                error={errors.Email && true}
                className={classes.margin}
              >
                <InputLabel shrink htmlFor="Email">
                  Email
                </InputLabel>
                <InputBase
                  value={email}
                  classes={{
                    root: classes.rootBootstrap,
                    input: classes.inputBootstrap,
                  }}
                  id="Email"
                  name="Email"
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  fullWidth
                  margin="normal"
                  autoComplete="off"
                  inputProps={{
                    maxLength: 200,
                    type: 'email',
                  }}
                  inputRef={register({
                    required: 'This field is Required',
                  })}
                />
                <FormHelperText>
                  {errors.Email && errors.Email.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <FormControl
                error={errors.ShopName && true}
                className={classes.margin}
              >
                <InputLabel shrink htmlFor="ShopName">
                  Shop web address
                </InputLabel>
                <InputBase
                  id="ShopName"
                  classes={{
                    root: classes.rootBootstrap,
                    input: classes.inputBootstrap,
                  }}
                  name="ShopName"
                  fullWidth
                  placeholder="eg.soonfatt"
                  value={shopname}
                  initialValue=""
                  onChange={(e) => {
                    setShopname(e.target.value.replace(/[^a-z]/g, ''))
                  }}
                  margin="normal"
                  autoComplete="off"
                  inputProps={{
                    maxLength: 200,
                  }}
                  inputRef={register({
                    required: 'This field is Required',
                  })}
                />
                <FormHelperText>
                  {errors.ShopName && errors.ShopName.message}
                  <React.Fragment>
                    Example: <b>soonfatt</b>.frapp.cloud
                  </React.Fragment>
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <br />
              <Typography className={classes.margin} variant="h6" align="left">
                .frapp.cloud
              </Typography>
            </Grid>
          </Grid>

          <br />
          <br />
          <Button
            variant="contained"
            disableElevation
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </form>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  )
}

export default withRoot(SignUp)
