import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { UPDATE_CART, GET_CARTS, REMOVE_CART } from '../graphql/cart'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useEffect } from 'react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close'

const restApi = 'http://frapp.cloud:3501'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    maxWidth: '36ch',
  },
  listitem: {
    padding: 0,
    backgroundColor: '#ffffff',
  },
  inline: {
    display: 'inline',
    fontSize: 12,
    fontWeight: 'bold',
    color: 'black',
  },
  primarytext: {
    fontSize: 12,
  },
}))

export default function CardItem(props) {
  const classes = useStyles()

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const [dbhost, setDbhost] = useState(window.location.host.split('.')[0])
  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.updatecart
    const foundIndex = old.carts.findIndex((item) => item.id === latest.id)
    old.carts.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts] },
    })
  }

  const [updateCart] = useMutation(UPDATE_CART, { update: updateCache })

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    if (data.removecart) {
      const latest = old.carts.filter(
        (item) => item.ProductID != props.data.ProductID
      )
      cache.writeQuery({
        query: GET_CARTS,
        data: { carts: [...latest] },
      })
    }
  }

  const [removeCart] = useMutation(REMOVE_CART, { update: deleteCache })

  const truncate = (str, n) =>
    str.length > n ? str.substr(0, n - 1) + '...' : str

  useEffect(() => {
    //console.log(props)
  }, [props])

  return (
    <ListItem className={classes.listitem} alignItems="flex-start">
      <Grid
        style={{
          width: 'auto',
          paddingTop: 3,
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 3,
          fontSize: 12,
          fontWeight: 'bold',
        }}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <IconButton
          size="small"
          style={{ padding: 0 }}
          aria-label="AddBoxOutlinedIcon"
          onClick={() => {
            if (props)
              updateCart({
                variables: {
                  ProductID: props.data.ProductID,
                  PriceID: props.data.PriceID,
                  Qty: props.data.Qty + 1,
                },
              })
          }}
        >
          <ExpandLessIcon />
        </IconButton>
        <div
          style={{
            margin: 0,
            textAlign: 'center',
            fontWeight: 'bold',
            color: shopconfig.COLORPRIMARY,
          }}
        >
          {props.data.Qty}
        </div>
        <IconButton
          size="small"
          style={{ padding: 0 }}
          aria-label="AddBoxOutlinedIcon"
          onClick={() => {
            if (props && props.data.Qty > 1)
              updateCart({
                variables: {
                  ProductID: props.data.ProductID,
                  PriceID: props.data.PriceID,
                  Qty: props.data.Qty - 1,
                },
              })
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Grid>
      <ListItemAvatar>
        <Avatar
          style={{ width: 55, height: 50, paddingRight: 5 }}
          variant="square"
          alt={props.data.ProductName}
          src={`${restApi}/media/${dbhost}/uploads/invoice/${props.data.ProductImage}`}
        />
      </ListItemAvatar>
      <ListItemText
        onClick={() => {
          //props.setProduct(props.data)
        }}
        classes={{
          primary: classes.primarytext,
        }}
        primary={truncate(props.data.ProductName, 20)}
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              RM{props.data.InvoiceAmount}
            </Typography>
          </React.Fragment>
        }
      />
      <IconButton
        size="small"
        style={{ padding: 0, marginRight: 5, marginTop: 5 }}
        aria-label="AddBoxOutlinedIcon"
        onClick={() => {
          if (props) {
            props.setSelectedProductID(props.data.ProductID)
            props.removeCart({
              variables: {
                id: props.data.ProductID,
              },
            })
          }
        }}
      >
        <CloseIcon />
      </IconButton>
    </ListItem>
  )
}
