import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, List } from '@material-ui/core/'

import Divider from '@material-ui/core/Divider'
import { Link as Link2 } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Hidden from '@material-ui/core/Hidden'
import { GET_CARTS } from '../graphql/cart'
import { GET_POSTCODEPRICE } from '../graphql/postcode'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import TrolleyItem from './trolleyItem'
import Cookies from 'js-cookie'
import ProductDialog from './productDialog'

const restApi = 'http://frapp.cloud:3501'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  listRoot: {
    width: '100%',
    padding: 0,
  },
  media: {
    height: 400,
  },
  card: {
    borderRadius: 0,
  },
  paperroot: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    border: (props) => `1px solid ${props.COLORPRIMARY}`,
  },
  summaryRoot: {
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
  },
  dividerRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}))

export default function Trolley(props) {
  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const [openProduct, setOpenProduct] = React.useState(false)
  const [selectedProductID, setSelectedProductID] = React.useState()
  const [product, setProduct] = React.useState()

  const {
    data: { postcodeprice } = { postcodeprice: { id: null } },
  } = useQuery(GET_POSTCODEPRICE)

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
  } = useQuery(GET_CARTS, { skip: !Cookies.get('signedin') })

  useEffect(() => {
    if (carts.length == 0) return

    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  useEffect(() => {
    setFinalAmount(
      postcodeprice ? postcodeprice.Price + totalAmount : totalAmount
    )
  }, [postcodeprice, totalAmount])

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <List className={classes.listRoot}>
            {carts.map((cart, i) => (
              <React.Fragment key={i}>
                <Paper classes={{ root: classes.paperroot }} variant="outlined">
                  <TrolleyItem
                    data={cart}
                    setOpenProduct={setOpenProduct}
                    setProduct={setProduct}
                  />
                </Paper>
              </React.Fragment>
            ))}
          </List>
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            color="primary"
            component={Link2}
            to="/"
            onClick={() => {
              scrollToTop()
            }}
          >
            Back to Shopping
          </Button>
        </Grid>

        <Hidden only={['md', 'lg']}>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Paper classes={{ root: classes.summaryRoot }} variant="outlined">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  Items in Cart
                </Grid>
                <Grid item xs={12}>
                  <strong>{carts.length}</strong>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  Total Amount
                </Grid>
                <Grid item xs={12}>
                  <strong>RM {parseFloat(totalAmount).toFixed(2)}</strong>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  Delivery Charges
                </Grid>
                <Grid item xs={12}>
                  <strong>
                    RM{' '}
                    {parseFloat(
                      (postcodeprice && postcodeprice.Price) || 0
                    ).toFixed(2)}
                  </strong>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={2}>
                <Grid item xs={6} md={4} style={{ fontSize: 17 }}>
                  <strong>Order Total</strong>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={8}
                  style={{ textAlign: 'right', fontSize: 17 }}
                >
                  <strong>RM {parseFloat(finalAmount).toFixed(2)}</strong>
                </Grid>
              </Grid>
            </Paper>
            <Button
              disabled={carts.length === 0}
              fullWidth
              variant="contained"
              disableElevation
              color="primary"
              onClick={props.handleNext}
              className={classes.button}
            >
              Continue Checkout
            </Button>
          </Grid>
        </Hidden>

        <Hidden only={['sm', 'xs']}>
          <Grid item xs={12} md={4}>
            <Paper classes={{ root: classes.summaryRoot }} variant="outlined">
              <Grid container spacing={2}>
                <Grid item xs={6} md={8}>
                  Items in Cart
                </Grid>
                <Grid item xs={6} md={4} style={{ textAlign: 'right' }}>
                  <strong> {carts.length}</strong>
                </Grid>
              </Grid>

              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={2}>
                <Grid item xs={6} md={8}>
                  Total Amount
                </Grid>
                <Grid item xs={6} md={4} style={{ textAlign: 'right' }}>
                  <strong>RM {parseFloat(totalAmount).toFixed(2)}</strong>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  Delivery Charges
                </Grid>
                <Grid item xs={6} md={8} style={{ textAlign: 'right' }}>
                  <strong>
                    RM{' '}
                    {parseFloat(
                      (postcodeprice && postcodeprice.Price) || 0
                    ).toFixed(2)}
                  </strong>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={2}>
                <Grid item xs={6} md={4} style={{ fontSize: 17 }}>
                  <strong>Order Total</strong>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={8}
                  style={{ textAlign: 'right', fontSize: 17 }}
                >
                  <strong>RM {parseFloat(finalAmount).toFixed(2)}</strong>
                </Grid>
              </Grid>
            </Paper>
            <Button
              disabled={carts.length === 0}
              fullWidth
              variant="contained"
              disableElevation
              color="primary"
              onClick={props.handleNext}
              className={classes.button}
            >
              Continue Checkout
            </Button>
          </Grid>
        </Hidden>
      </Grid>

      <ProductDialog
        openProduct={openProduct}
        setOpenProduct={setOpenProduct}
        selectedProductID={selectedProductID}
        setSelectedProductID={setSelectedProductID}
        product={product}
        setProduct={setProduct}
      />
    </React.Fragment>
  )
}
