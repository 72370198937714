import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as Link2, useHistory } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import CartItem from './cartItem'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'

import ImageGallery from 'react-image-gallery'

import 'react-image-gallery/styles/css/image-gallery.css'
import Flickity from 'react-flickity-component'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import HomeIcon from '@material-ui/icons/Home'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import GrainIcon from '@material-ui/icons/Grain'
import Cookies from 'js-cookie'

const restApi = 'http://frapp.cloud:3501'
const useStyles = makeStyles((theme) => ({
  paperroot: {
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
    marginBottom: theme.spacing(1),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  productDesc: {
    fontWeight: 'normal',
  },
}))

export default function ShopDetails(props) {
  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)
  const history = useHistory()

  const [dbhost, setDbhost] = useState(window.location.host.split('.')[0])
  const images = [
    {
      original: `${restApi}/media/${dbhost}/uploads/invoice/${props.data.ProductImage}`,
      thumbnail: `${restApi}/media/${dbhost}/uploads/invoice/${props.data.ProductImage}`,
    },
  ]

  /* 
  const flickityOptions = {
    initialIndex: 0,
    autoPlay: 3000,
    pauseAutoPlayOnHover: false,
    wrapAround: false,
    fullscreen: true,
    adaptiveHeight: false,
    prevNextButtons: true,
    pageDots: true,
    imagesLoaded: true,
    lazyLoad: true,
    setGallerySize: false,
  } */

  if (!props.data.id) return <div>no item</div>

  return (
    <React.Fragment>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Typography color="textPrimary">
          <HomeIcon className={classes.icon} />
        </Typography>
        <Typography color="textPrimary">{props.data.Category}</Typography>
      </Breadcrumbs> */}
      <Paper classes={{ root: classes.paperroot }} variant="outlined">
        <div>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            style={{ minWidth: 0, marginBottom: 20 }}
            onClick={() => {
              props.setBackProductID(props.data.id)
              props.setBack(new Date() + Math.random())
              props.setProduct(null)
            }}
          >
            <ArrowBackIosIcon /> Back
          </Button>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={5}>
            {/* <Flickity
              className={'carousel'} // default ''
              elementType={'div'} // default 'div'
              options={flickityOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate={true} // default false
              static={false} // default false
            >
              <img
                width="100%"
                src={`${restApi}/${dbhost}/uploads/media/invoice/${props.data.ProductImage}`}
                alt=""
              />
              <img
                width="100%"
                src={`${restApi}/${dbhost}/uploads/media/invoice/${props.data.ProductImage}`}
                alt=""
              />
            </Flickity> */}
            <ImageGallery
              showPlayButton={false}
              showFullscreenButton={true}
              useBrowserFullscreen={true}
              items={images}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <h2 style={{ marginBottom: 0 }}>{props.data.ProductName}</h2>
            <Typography variant="body2" color="textSecondary" component="p">
              {`${props.data.Category}`}
            </Typography>
            <div style={{ paddingTop: 5, minHeight: 30 }}>
              {props.data.ProductPrices.split(',').map((price, i) => {
                const priceitem = price.split('|')
                return (
                  <div key={i}>
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                        display: 'inline-block',
                        paddingRight: 10,
                      }}
                    >
                      {priceitem[0]}
                    </span>
                  </div>
                )
              })}
            </div>
            <div
              className={classes.productDesc}
              dangerouslySetInnerHTML={{ __html: props.data.ProductDesc }}
            />
            <div style={{ marginTop: 30 }}>
              {!props.carts.find((a) => a.ProductID == props.data.id) && (
                <Button
                  disabled={props.selectedProductID == props.data.id}
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => {
                    if (!Cookies.get('signedin')) {
                      history.push('/login')
                      return
                    }
                    props.setSelectedProductID(props.data.id)
                    props.insertCart({
                      variables: {
                        ProductID: props.data.id,
                        PriceID: props.data.PriceID,
                        Qty: 1,
                      },
                    })
                  }}
                >
                  Add
                </Button>
              )}
              {props.carts.find((a) => a.ProductID == props.data.id) && (
                <React.Fragment>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    style={{ minWidth: 0, marginRight: 10 }}
                    onClick={() => {
                      if (!Cookies.get('signedin')) {
                        history.push('/login')
                        return
                      }
                      props.updateCart({
                        variables: {
                          ProductID: props.data.id,
                          PriceID: props.data.PriceID,
                          Qty:
                            props.carts.find(
                              (a) => a.ProductID == props.data.id
                            ).Qty + 1,
                        },
                      })
                    }}
                  >
                    <AddIcon />
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    style={{ minWidth: 0 }}
                    onClick={() => {
                      if (!Cookies.get('signedin')) {
                        history.push('/login')
                        return
                      }
                      if (
                        props.carts.find((a) => a.ProductID == props.data.id)
                          .Qty > 1
                      ) {
                        props.updateCart({
                          variables: {
                            ProductID: props.data.id,
                            PriceID: props.data.PriceID,
                            Qty:
                              props.carts.find(
                                (a) => a.ProductID == props.data.id
                              ).Qty - 1,
                          },
                        })
                      } else {
                        props.setSelectedProductID(props.data.id)
                        setTimeout(() => {
                          props.removeCart({
                            variables: {
                              id: props.data.id,
                            },
                          })
                        }, 1)
                      }
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                </React.Fragment>
              )}
            </div>
            <div
              style={{
                minHeight: props.carts.find((a) => a.ProductID == props.data.id)
                  ? 38
                  : 40,
                textAlign: 'left',
              }}
            >
              {props.carts.find((a) => a.ProductID == props.data.id) && (
                <h3
                  style={{
                    margin: 0,
                    paddingTop: 10,
                    color: shopconfig.COLORPRIMARY,
                  }}
                >
                  {props.carts.find((a) => a.ProductID == props.data.id).Qty} in
                  trolley
                </h3>
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  )
}
