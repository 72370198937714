import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import {
  UPDATE_ORDER,
  GET_ORDERS,
  CREATE_ORDER,
  REMOVE_ORDER,
} from '../graphql/order'
import {
  INSERT_PRODUCT,
  GET_PRODUCTS,
  UPDATE_PRODUCT,
  PRODUCT_IMAGES,
  MAKE_IMAGEPRIMARY,
  GET_PRICELIST,
  REMOVE_PRODUCT,
} from '../graphql/product'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import ProductPriceDialog from './productPriceDialog'
import ProductCancelDialog from './productCancelDialog'
import ProductUploadDialog from './productUploadDialog'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'

const restApi = 'http://frapp.cloud:3501'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: '#efefef',
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    padding: theme.spacing(2),
    height: 64,
    color: theme.palette.primary.contrastText,
  },
  dialogContent: {
    padding: theme.spacing(2),
    background: '#efefef',
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[50],
  },
  dialogActions: {
    background: theme.palette.primary.paper,
    padding: theme.spacing(2),
  },
  backDrop: { backgroundColor: 'transparent' },
}))

export default function ProductDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirm, setConfirm] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [openUpload, setOpenUpload] = useState(false)
  const [openPrice, setOpenPrice] = useState(false)
  const [openImage, setOpenImage] = useState(false)
  const [confirmDeleteUpload, setConfirmDeleteUpload] = useState(false)
  const [showImage, setShowImage] = useState()
  const [selectedImage, setSelectedImage] = useState()
  const [selectedPrice, setSelectedPrice] = useState()
  const [productDesc, setProductDesc] = useState()
  const [dbhost, setDbhost] = useState(window.location.host.split('.')[0])

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PRODUCTS,
    })
    const latest = data.insertproduct
    cache.writeQuery({
      query: GET_PRODUCTS,
      data: { products: [latest, ...old.products] },
    })

    props.setSelected([latest.id])
    props.setProduct(latest)
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PRODUCTS,
    })
    const latest = data.updateproduct
    const foundIndex = old.products.findIndex((item) => item.id === latest.id)
    old.products.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_PRODUCTS,
      data: { products: [...old.products] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setProduct()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PRODUCTS,
    })
    if (data.removeproduct) {
      const latest = old.products.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_PRODUCTS,
        data: { products: [...latest] },
      })
      props.setSelected([])
      props.setProduct()
    }
  }

  const [insertProduct] = useMutation(INSERT_PRODUCT, { update: addCache })
  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    update: updateCache,
  })
  const [removeProduct] = useMutation(REMOVE_PRODUCT, {
    update: deleteCache,
  })
  const [makePrimaryImage] = useMutation(MAKE_IMAGEPRIMARY)

  const {
    loading: loadingProductImages,
    data: { productimages } = { productimages: [] },
    refetch: refetchProductImages,
  } = useQuery(PRODUCT_IMAGES, {
    variables: { id: props.data && props.data.id },
    skip: !props.data,
  })

  const {
    loading: loadingPriceList,
    data: { pricelist } = { pricelist: [] },
    refetch: refetchPriceList,
  } = useQuery(GET_PRICELIST, {
    variables: { ProductID: props.data && props.data.id },
    skip: !props.data,
  })

  useEffect(() => {
    if (!props.data) return
  }, [props])

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updateProduct({
        variables: {
          id: props.data.id,
          Category: values.Category,
          Ordering: values.Ordering,
          IDSKU: values.IDSKU,
          ProductName: values.ProductName,
          Quantity: values.Quantity,
          UnitPrice: parseFloat(values.UnitPrice),
          ProductDesc: productDesc,
        },
      })
    } else {
      insertProduct({
        variables: {
          Category: values.Category,
          Ordering: values.Ordering,
          IDSKU: values.IDSKU,
          ProductName: values.ProductName,
          Quantity: values.Quantity,
          UnitPrice: parseFloat(values.UnitPrice),
          ProductDesc: productDesc,
        },
      })
    }
  }

  const handleClickDelete = () => {
    removeProduct({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
    props.setOpen(false)
    props.setSelected([])
    props.setProduct()
  }

  const handleClickDeleteImage = () => {
    axios
      .get(`${restApi}/upload/remove/${selectedImage}`)
      .then((response) => {
        refetchProductImages()
      })
      .catch((error) => console.log(error))
    setConfirmDeleteUpload(false)
  }

  const handleEditorChange = (e) => {
    setProductDesc(e.target.getContent())
    //console.log('Content was updated:', e.target.getContent())
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Product?"
        okButton="Yes"
        title="Continue remove"
      />
      <ConfirmationDialog
        action={handleClickDeleteImage}
        confirm={confirmDeleteUpload}
        setConfirm={setConfirmDeleteUpload}
        message="Continue remove Product Image?"
        okButton="Yes"
        title="Continue remove"
      />
      <ProductUploadDialog
        data={props.data}
        open={openUpload}
        setOpen={setOpenUpload}
        refetch={refetchProductImages}
      />
      <ProductPriceDialog
        open={openPrice}
        setOpen={setOpenPrice}
        product={props.data}
        data={selectedPrice}
        setSelected={setSelectedPrice}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
          props.setSelected([])
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="order-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="order-dialog">
            Product
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
                props.setSelected([])
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                  {/* <TextField
                    name="Category"
                    label="Category"
                    margin="dense"
                    fullWidth
                    defaultValue={props.data && props.data.Category}
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.Category}
                    helperText={errors.Category && errors.Category.message}
                  /> */}
                  {/*  <TextField
                    name="IDSKU"
                    label="SKU"
                    margin="dense"
                    defaultValue={props.data && props.data.IDSKU}
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.IDSKU}
                    helperText={errors.IDSKU && errors.IDSKU.message}
                  /> */}
                  <TextField
                    name="ProductName"
                    label="Product Name"
                    margin="dense"
                    fullWidth
                    defaultValue={props.data && props.data.ProductName}
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.ProductName}
                    helperText={
                      errors.ProductName && errors.ProductName.message
                    }
                  />
                  <TextField
                    name="Ordering"
                    label="Ordering"
                    margin="dense"
                    type="number"
                    fullWidth
                    defaultValue={props.data && props.data.Ordering}
                    style={{ width: 200 }}
                    autoComplete="off"
                    inputProps={{ min: '0', step: 'any' }}
                    inputRef={register({ required: 'Required' })}
                    error={errors.Ordering}
                    helperText={errors.Ordering && errors.Ordering.message}
                  />
                  <br />
                  <br />

                  {props.data && props.data.id && (
                    <React.Fragment>
                      <h5 className={classes.h5}> Price</h5>
                      <List className={classes.root}>
                        {pricelist.map((price, i) => (
                          <ListItem
                            key={i}
                            dense
                            button
                            onClick={() => {
                              setSelectedPrice(price)
                              setOpenPrice(true)
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar>{i + 1}</Avatar>
                            </ListItemAvatar>
                            {/* <ListItemText
                              primary={`${price.Unit} ${price.Uom}`}
                              secondary={`RM ${price.UnitPrice}`}
                            />  */}
                            <ListItemText primary={`RM ${price.UnitPrice}`} />
                          </ListItem>
                        ))}
                        {props.data && pricelist.length === 0 && (
                          <React.Fragment>No Price found.</React.Fragment>
                        )}
                      </List>
                      {props.data && pricelist.length === 0 && (
                        <div style={{ textAlign: 'center' }}>
                          <Button
                            onClick={() => {
                              setOpenPrice(true)
                            }}
                            variant="contained"
                            disableElevation
                            color="primary"
                          >
                            Add Price
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  {/* <TextField
                    name="UnitPrice"
                    label="Unit Price"
                    margin="dense"
                    type="number"
                    fullWidth
                    defaultValue={props.data && props.data.UnitPrice}
                    style={{ width: 200 }}
                    autoComplete="off"
                    inputProps={{ min: '0', step: 'any' }}
                    inputRef={register({ required: 'Required' })}
                    error={errors.UnitPrice}
                    helperText={errors.UnitPrice && errors.UnitPrice.message}
                  /> */}

                  {/* <TextField
                    name="ProductDesc"
                    label="ProductDesc"
                    margin="dense"
                    defaultValue={props.data && props.data.ProductDesc}
                    fullWidth
                    multiline
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.ProductDesc}
                    helperText={
                      errors.ProductDesc && errors.ProductDesc.message
                    }
                  /> */}
                  <Divider orientation="vertical" flexItem />
                </Grid>
                <Grid
                  style={{ paddingTop: 0, borderLeft: '1px solid #ddd' }}
                  item
                  md={6}
                  xs={12}
                >
                  {props.data && props.data.id && (
                    <React.Fragment>
                      <h5 className={classes.h5}>Images</h5>
                      <List className={classes.root}>
                        {props.data &&
                          props.data.id &&
                          productimages.map((image, i) => (
                            <ListItem key={i}>
                              <img
                                src={`${restApi}/media/${dbhost}/uploads/invoice/${image.FileName}`}
                                className={classes.listimage}
                                onClick={() => {
                                  setShowImage(
                                    `${restApi}/media/${dbhost}/uploads/invoice/${image.FileName}`
                                  )
                                  setOpenImage(!openImage)
                                }}
                              />
                              <ListItemText
                                primary={image.Ordering == 0 ? 'Primary' : ''}
                              />
                              {image.Ordering != 0 && (
                                <Button
                                  onClick={() => {
                                    makePrimaryImage({
                                      variables: {
                                        id: image.id,
                                        ModuleID: props.data && props.data.id,
                                      },
                                    })
                                    setTimeout(() => {
                                      refetchProductImages()
                                    }, 2000)
                                  }}
                                  color="primary"
                                >
                                  Make Primary
                                </Button>
                              )}
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() => {
                                    setSelectedImage(image.id)
                                    setConfirmDeleteUpload(true)
                                  }}
                                  edge="end"
                                  aria-label="comments"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                      </List>
                      {openImage && (
                        <dialog
                          className={classes.imagedialog}
                          style={{ position: 'absolute' }}
                          open
                        >
                          <img
                            className={classes.big}
                            src={showImage}
                            onClick={() => {
                              setOpenImage(false)
                            }}
                          />
                        </dialog>
                      )}
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          onClick={() => {
                            setOpenUpload(true)
                          }}
                          variant="contained"
                          disableElevation
                          color="primary"
                        >
                          Add Image
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                </Grid>
                <Grid style={{ paddingTop: 0 }} item md={12} xs={12}>
                  <h5 className={classes.h5}>Product Details</h5>
                  <Editor
                    apiKey="ddwtpmbv25bslslv86dfnxcn8n9tzcdg44odrmgp2h9phjty"
                    initialValue={props.data && props.data.ProductDesc}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image',
                        'charmap print preview anchor help',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                          alignleft aligncenter alignright | \
                          bullist numlist outdent indent',
                    }}
                    onChange={handleEditorChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {props.data && (
                <IconButton
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setConfirm(true)
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
