import withRoot from './modules/withRoot'
// --- Post bootstrap -----
import React, { useState } from 'react'
import { fade, makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { Redirect } from 'react-router-dom'
import Typography from './modules/components/Typography'
import AppFooter from './modules/views/AppFooter'
import AppAppBar from './modules/views/AppAppBar'
import AppForm from './modules/views/AppForm'
import { email, required } from './modules/form/validation'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import { useSnackbar } from 'notistack'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import PinInput from 'react-pin-input'
import Cookies from 'js-cookie'

import useForm from 'react-hook-form'

const url = 'http://frapp.cloud:3501/users/register'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  paperroot: {
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
  },
  toolbar: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  dividerRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  margin: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  containerroot: {
    paddingTop: 0,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  margin2: {
    width: '100%',
    marginTop: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #00b8d4',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.secondary.main,
    },
  },
}))

function Register() {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [sent, setSent] = React.useState(false)
  const { handleSubmit, register, errors } = useForm()
  const [shopname, setShopname] = useState(Cookies.get('shopname'))
  const [pinno, setPinno] = useState('')

  const onSubmit = (values) => {
    const options = {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: `pinno=${pinno}&shopname=${shopname}`,
    }

    fetch(url, options)
      .then((response) => {
        /* if (!response.ok) {
          if (response.status === 404) {
            alert('Username not found, please retry')
          }
          if (response.status === 401) {
            alert('Username and password do not match, please retry')
          }
          if (response.status === 500) {
            alert('Username and password do not match, please retry')
          }
        } */
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.message == 'Insert Completed') {
          Cookies.remove('signup')
          Cookies.remove('signup', { path: '/signup' })
          Cookies.remove('signup', { path: '' })
          Cookies.remove('signup', { path: '/register' })
          Cookies.remove('shopname')
          Cookies.remove('shopname', { path: '' })
          Cookies.remove('shopname', { path: '/register' })
          Cookies.remove('shopname', { path: '/signup' })
          setTimeout(() => {
            window.location.assign(`http://${shopname}.frapp.cloud`)
          }, 1000)
        } else {
          enqueueSnackbar(data.message, {
            variant: 'error',
          })
        }
      })

    setSent(true)
  }

  const renderRedirect = () => {
    if (!Cookies.get('signup')) {
      return <Redirect to="/signup" />
    }
  }

  return (
    <React.Fragment>
      {renderRedirect()}
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Verify Your Mobile No
          </Typography>
          <Typography variant="body2" align="center">
            A pin code is sent to your Whatsapp account.
          </Typography>
          <br />
        </React.Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <PinInput
                length={6}
                focus
                type="numeric"
                inputStyle={{ fontSize: 14 }}
                onChange={(value) => {
                  setPinno(value)
                }}
              />
            </Grid>
          </Grid>

          <br />
          <br />
          <Button
            variant="contained"
            disableElevation
            color="primary"
            type="submit"
            disabled={pinno.length != 6}
          >
            Submit
          </Button>
        </form>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  )
}

export default withRoot(Register)
