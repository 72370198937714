import React, { useState } from 'react'
import { Link as Link2, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core/'

import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import { GET_CARTS } from '../graphql/cart'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import { GET_BUYER } from '../graphql/buyer'
import { GET_POSTCODEPRICE } from '../graphql/postcode'
import { GET_SHOPCONFIG } from '../graphql/config'
import Cookies from 'js-cookie'
import Hidden from '@material-ui/core/Hidden'
import EditIcon from '@material-ui/icons/Edit'

const restApi = 'http://frapp.cloud:3501'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  paperroot: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    border: (props) => `1px solid ${props.COLORPRIMARY}`,
    borderBottom: (props) => `5px solid ${props.COLORPRIMARY}`,
  },
  summaryRoot: {
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
  },
  dividerRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}))

export default function OrderSummary(props) {
  const history = useHistory()
  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const {
    loading: loadingBuyer,
    data: { buyer } = { buyer: { id: null } },
  } = useQuery(GET_BUYER, { skip: !Cookies.get('signedin') })

  const { loading: loadingCarts, data: { carts } = { carts: [] } } = useQuery(
    GET_CARTS
  )

  const {
    data: { postcodeprice } = { postcodeprice: { id: null } },
  } = useQuery(GET_POSTCODEPRICE)

  useEffect(() => {
    if (carts.length == 0) return

    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  useEffect(() => {
    setFinalAmount(postcodeprice.id && postcodeprice.Price + totalAmount)
  }, [postcodeprice, totalAmount])

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Hidden only={['sm', 'xs']}>
          <Grid item xs={12} md={2}></Grid>
        </Hidden>
        <Grid item xs={12} md={4}>
          <Paper classes={{ root: classes.summaryRoot }} variant="outlined">
            <div>Deliver in 2-3 business days at the following address: </div>
            <h3>Delivery Address</h3>
            <div>{buyer.id && buyer.Address1}</div>
            <div>{buyer.id && buyer.Address2}</div>
            <div>
              {buyer.Postcode} {buyer.id && buyer.City}
            </div>
            <div>{buyer.id && buyer.State}</div>
            <div style={{ marginTop: 20 }}></div>

            <Button
              className={classes.button}
              onClick={() => {
                history.push('/customer')
              }}
              startIcon={<EditIcon />}
            >
              Update Address
            </Button>
          </Paper>
        </Grid>

        <Hidden only={['md', 'lg']}>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Paper classes={{ root: classes.summaryRoot }} variant="outlined">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  Items in Cart
                </Grid>
                <Grid item xs={12}>
                  <strong>{carts.length}</strong>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  Total Amount
                </Grid>
                <Grid item xs={12}>
                  <strong>RM {parseFloat(totalAmount).toFixed(2)}</strong>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  Delivery Charges
                </Grid>
                <Grid item xs={12}>
                  <strong>
                    RM {parseFloat(postcodeprice.Price).toFixed(2)}
                  </strong>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={2}>
                <Grid item xs={6} md={4} style={{ fontSize: 17 }}>
                  <strong>Order Total</strong>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={8}
                  style={{ textAlign: 'right', fontSize: 17 }}
                >
                  <strong>RM {parseFloat(finalAmount).toFixed(2)}</strong>
                </Grid>
              </Grid>
            </Paper>
            <Button
              disabled={carts.length === 0}
              fullWidth
              variant="contained"
              disableElevation
              color="primary"
              onClick={props.handleNext}
              className={classes.button}
            >
              Continue Checkout
            </Button>
          </Grid>
        </Hidden>

        <Hidden only={['sm', 'xs']}>
          <Grid item xs={12} md={4}>
            <Paper classes={{ root: classes.summaryRoot }} variant="outlined">
              <Grid container spacing={2}>
                <Grid item xs={6} md={8}>
                  Items in Cart
                </Grid>
                <Grid item xs={6} md={4} style={{ textAlign: 'right' }}>
                  <strong> {carts.length}</strong>
                </Grid>
              </Grid>

              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={2}>
                <Grid item xs={6} md={8}>
                  Total Amount
                </Grid>
                <Grid item xs={6} md={4} style={{ textAlign: 'right' }}>
                  <strong>RM {parseFloat(totalAmount).toFixed(2)}</strong>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  Delivery Charges
                </Grid>
                <Grid item xs={6} md={8} style={{ textAlign: 'right' }}>
                  <strong>
                    RM {parseFloat(postcodeprice.Price).toFixed(2)}
                  </strong>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid container spacing={2}>
                <Grid item xs={6} md={4} style={{ fontSize: 17 }}>
                  <strong>Order Total</strong>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={8}
                  style={{ textAlign: 'right', fontSize: 17 }}
                >
                  <strong>RM {parseFloat(finalAmount).toFixed(2)}</strong>
                </Grid>
              </Grid>
            </Paper>
            <Button
              disabled={carts.length === 0}
              fullWidth
              variant="contained"
              disableElevation
              color="primary"
              onClick={props.handleNext}
              className={classes.button}
            >
              Continue Checkout
            </Button>
          </Grid>
        </Hidden>

        <Grid item sm={12} md={2}></Grid>
      </Grid>
    </React.Fragment>
  )
}
