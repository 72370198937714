import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  CardMedia,
  List,
  Link,
} from '@material-ui/core/'

import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import { Link as Link2, useHistory } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import Pagination from '@material-ui/lab/Pagination'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Cookies from 'js-cookie'
import Cart from './cart'
import { GET_PRODUCTS, TOTAL_PRODUCTS } from '../graphql/product'
import {
  GET_CARTS,
  INSERT_CART,
  UPDATE_CART,
  REMOVE_CART,
} from '../graphql/cart'

import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import ShopDetails from './shopDetails'
import Loading from '../common/loading'
import Menu from './menu'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import ProductDialog from './productDialog'

const restApi = 'http://frapp.cloud:3501'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  addRoot: {
    minWidth: 0,
    marginTop: 3,
    marginRight: 5,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
    boxShadow: 'none',
  },
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  paddingleft: {
    padding: 8,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
    },
  },
  iconbuttonroot: {
    padding: 0,
  },
}))

export default function Shop(props) {
  const classes = useStyles()
  const history = useHistory()
  const [selectedProductID, setSelectedProductID] = useState()
  const [product, setProduct] = useState()
  const [productMobile, setProductMobile] = useState()
  const [back, setBack] = useState()
  const [backProductID, setBackProductID] = useState()
  const [currPage, setCurrPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [maxPages, updateMaxPages] = useState(0)
  const [viewLocation, setViewLocation] = useState()

  const [companyName, setCompanyName] = useState()
  const [companyLogo, setCompanyLogo] = useState()

  const [dbhost, setDbhost] = useState(window.location.host.split('.')[0])

  const [openProduct, setOpenProduct] = React.useState(false)
  const [totalAmount, setTotalAmount] = useState(0)

  //const [dbhost, setDbhost] = useState(window.location.host.split('.')[0])

  /*  const {
    loading: loadingTotal,
    data: { numberOfProducts } = { numberOfProducts: { total: 0 } },
  } = useQuery(TOTAL_PRODUCTS) */

  const {
    loading: loadingProducts,
    data: { products } = { products: [] },
    refetch,
  } = useQuery(GET_PRODUCTS, {
    variables: {
      offset: 0,
      limit: 12,
    },
    fetchPolicy: 'cache-and-network',
  })

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const onLoadMore = () => {
    refetch({
      offset: offset,
      limit: 12,
    })

    /* fetchMore({
      variables: {
        offset: currPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          products: [...fetchMoreResult.products],
        })
      },
    }) */
  }

  const getNextPage = () => {
    setCurrPage(currPage + 1)
  }

  const getPrevPage = () => {
    if (currPage > 0) setCurrPage(currPage - 1)
  }
  const handleChangePage = (event, value) => {
    scrollToTop()
    /* if (!products[0]) return
    setBackProductID(products[0].id)
    setBack(new Date() + Math.random()) */

    setCurrPage(value)
    setOffset(value - 1)
  }

  useEffect(() => {
    onLoadMore()
  }, [currPage])

  /* useEffect(() => {
    const onCompleted = (dTotal) => {}

    if (dTotal && dTotal.numberOfSuggestions) {
      const totalNumberOfPages = Math.ceil(dTotal.numberOfSuggestions / 10)
      updateMaxPages(totalNumberOfPages)
    }
  }, [dTotal]) */

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      hi
    </div>
  )

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch: refetchCart,
  } = useQuery(GET_CARTS, { skip: !Cookies.get('signedin') })

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.insertcart
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts, latest] },
    })
  }

  const [insertCart] = useMutation(INSERT_CART, {
    update: addCache,
  })

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.updatecart
    const foundIndex = old.carts.findIndex((item) => item.id === latest.id)
    old.carts.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts] },
    })
  }

  const [updateCart] = useMutation(UPDATE_CART, { update: updateCache })

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })

    if (data.removecart) {
      const latest = old.carts.filter(
        (item) => item.ProductID != data.removecart
      )
      cache.writeQuery({
        query: GET_CARTS,
        data: { carts: [...latest] },
      })
    }

    setSelectedProductID()
  }

  const [removeCart] = useMutation(REMOVE_CART, { update: deleteCache })

  const myRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ])

  const executeScroll = (a) => {
    //myRefs.current[5].scrollIntoView({ behavior: 'smooth', block: 'start' })
    //scrollToRef(myRefs.current[5])
  }

  useEffect(() => {
    console.log('viewLocation', viewLocation)
    setTimeout(() => {
      window.scrollTo(0, viewLocation)
    }, 1)
    /* myRefs.current[backProductID].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      }) */
  }, [back])

  useEffect(() => {
    refetchCart()
  }, [props])

  useEffect(() => {
    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  return (
    <React.Fragment>
      <Hidden only={['sm', 'xs']}>
        <div style={{ backgroundColor: 'white' }}>
          <Container maxWidth="md">
            <div style={{ minHeight: 100 }} className={classes.toolbar}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  paddingTop: 10,
                }}
              >
                <Link2 to="/">
                  <img
                    style={{ width: 50, verticalAlign: 'middle' }}
                    src={`${restApi}/media/${dbhost}/uploads/logo/${shopconfig.COMLOGO}`}
                  />
                </Link2>
                <h3 style={{ paddingLeft: 10 }}>{shopconfig.COMSHO}</h3>
              </div>
              <Menu value={0} />
            </div>
          </Container>
        </div>
      </Hidden>
      <Divider />
      <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <div className={classes.toolbar}>
          <div className={classes.root}>
            <div style={{ display: 'flex' }}>
              <div className={classes.paddingleft} style={{ flexGrow: 1 }}>
                {product && (
                  <ShopDetails
                    viewLocation={viewLocation}
                    setBackProductID={setBackProductID}
                    setBack={setBack}
                    executeScroll={executeScroll}
                    carts={carts}
                    setSelectedProductID={setSelectedProductID}
                    selectedProductID={selectedProductID}
                    setProduct={setProduct}
                    data={product}
                    insertCart={insertCart}
                    updateCart={updateCart}
                    removeCart={removeCart}
                  />
                )}
                {/*  {loadingProducts && <Loading />} */}
                {!loadingProducts && !product && (
                  <React.Fragment>
                    <Hidden only={['md', 'lg']}>
                      {products.map((product, i) => (
                        <Card
                          ref={myRefs.current[product.id]}
                          variant="outlined"
                          style={{
                            marginBottom: 8,
                            padding: 5,
                            border: carts.find((a) => a.ProductID == product.id)
                              ? `1px solid ${shopconfig.COLORPRIMARY}`
                              : '1px solid #b4b4b4',
                            border: carts.find((a) => a.ProductID == product.id)
                              ? `1px solid ${shopconfig.COLORPRIMARY}`
                              : '1px solid #b4b4b4',
                          }}
                          classes={{ root: classes.card }}
                        >
                          <Grid container spacing={1} key={i}>
                            <Grid item xs={4}>
                              <Link
                                style={{
                                  display: 'block',
                                  fontSize: 0,
                                  padding: 0,
                                  margin: 0,
                                }}
                                href=""
                                onClick={(e) => {
                                  e.preventDefault()
                                  product.ProductID = product.id
                                  setProductMobile(product)
                                  setOpenProduct(true)
                                }}
                              >
                                <img
                                  style={{ width: '100%' }}
                                  src={`${restApi}/media/${dbhost}/uploads/invoice/${product.ProductImage}`}
                                />
                              </Link>
                            </Grid>
                            <Grid item xs={8}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      fontSize: 17,
                                      fontWeight: 'bold',
                                      color: '#cf242a',
                                      marginTop: 0,
                                      marginBottom: 3,
                                      lineHeight: '1.2',
                                    }}
                                  >
                                    <Link
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault()
                                        product.ProductID = product.id
                                        setProductMobile(product)
                                        setOpenProduct(true)
                                      }}
                                    >
                                      {product.ProductName}
                                    </Link>
                                  </div>

                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                  >
                                    {`${product.Category}`}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <div>
                                    {product.ProductPrices.split(',').map(
                                      (price, i) => {
                                        const priceitem = price.split('|')
                                        return (
                                          <div key={i}>
                                            <span
                                              style={{
                                                fontWeight: 'bold',
                                                fontSize: 16,
                                                display: 'inline-block',
                                                paddingRight: 10,
                                              }}
                                            >
                                              {priceitem[0]}
                                            </span>
                                            {/* <br />
                                            <span style={{}}>
                                              {priceitem[1]}
                                            </span> */}
                                          </div>
                                        )
                                      }
                                    )}
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  alignContent="center"
                                  justify="center"
                                  style={{
                                    textAlign: 'right',
                                    paddingRight: 5,
                                    paddingBottom: 0,
                                  }}
                                  xs={6}
                                >
                                  {!carts.find(
                                    (a) => a.ProductID == product.id
                                  ) && (
                                    <Button
                                      disabled={selectedProductID == product.id}
                                      disableElevation
                                      variant="contained"
                                      disableElevation
                                      size="dense"
                                      color="primary"
                                      style={{
                                        marginRight: 0,
                                      }}
                                      classes={{ root: classes.addRoot }}
                                      onClick={() => {
                                        if (!Cookies.get('signedin')) {
                                          scrollToTop()
                                          history.push('/login')
                                          return
                                        }
                                        setSelectedProductID(product.id)
                                        insertCart({
                                          variables: {
                                            ProductID: product.id,
                                            PriceID: product.PriceID,
                                            Qty: 1,
                                          },
                                        })
                                      }}
                                    >
                                      Add
                                    </Button>
                                  )}
                                  {carts.find(
                                    (a) => a.ProductID == product.id
                                  ) && (
                                    <React.Fragment>
                                      <IconButton
                                        size="medium"
                                        edge={false}
                                        classes={{
                                          root: classes.iconbuttonroot,
                                        }}
                                        onClick={() => {
                                          if (!Cookies.get('signedin')) {
                                            scrollToTop()
                                            history.push('/login')
                                            return
                                          }
                                          if (
                                            carts.find(
                                              (a) => a.ProductID == product.id
                                            ).Qty > 1
                                          ) {
                                            updateCart({
                                              variables: {
                                                ProductID: product.id,
                                                PriceID: product.PriceID,
                                                Qty:
                                                  carts.find(
                                                    (a) =>
                                                      a.ProductID == product.id
                                                  ).Qty - 1,
                                              },
                                            })
                                          } else {
                                            setSelectedProductID(product.id)
                                            setTimeout(() => {
                                              removeCart({
                                                variables: {
                                                  id: product.id,
                                                },
                                              })
                                            }, 1)
                                          }
                                        }}
                                      >
                                        <RemoveCircleOutlineIcon
                                          style={{
                                            color: shopconfig.COLORPRIMARY,
                                            fontSize: 32,
                                          }}
                                        />
                                      </IconButton>
                                      <span
                                        style={{
                                          width: 25,
                                          textAlign: 'center',
                                          display: 'inline-block',
                                          fontSize: 16,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {
                                          carts.find(
                                            (a) => a.ProductID == product.id
                                          ).Qty
                                        }
                                      </span>
                                      <IconButton
                                        size="medium"
                                        edge={false}
                                        classes={{
                                          root: classes.iconbuttonroot,
                                        }}
                                        onClick={() => {
                                          if (!Cookies.get('signedin')) {
                                            scrollToTop()
                                            history.push('/login')
                                            return
                                          }
                                          updateCart({
                                            variables: {
                                              ProductID: product.id,
                                              PriceID: product.PriceID,
                                              Qty:
                                                carts.find(
                                                  (a) =>
                                                    a.ProductID == product.id
                                                ).Qty + 1,
                                            },
                                          })
                                        }}
                                      >
                                        <AddCircleIcon
                                          style={{
                                            color: shopconfig.COLORPRIMARY,
                                            fontSize: 32,
                                          }}
                                        />
                                      </IconButton>
                                    </React.Fragment>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      ))}
                    </Hidden>
                  </React.Fragment>
                )}
                {!product && (
                  <Hidden only={['sm', 'xs']}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      {products.map((product, i) => (
                        <Grid
                          ref={myRefs.current[product.id]}
                          item
                          xs={6}
                          sm={6}
                          md={3}
                          key={i}
                        >
                          <Card
                            variant="outlined"
                            style={{
                              border: carts.find(
                                (a) => a.ProductID == product.id
                              )
                                ? `1px solid ${shopconfig.COLORPRIMARY}`
                                : '1px solid #b4b4b4',
                              borderBottom: carts.find(
                                (a) => a.ProductID == product.id
                              )
                                ? `5px solid ${shopconfig.COLORPRIMARY}`
                                : '1px solid #b4b4b4',
                            }}
                            classes={{ root: classes.card }}
                          >
                            <Link
                              href=""
                              onClick={(e) => {
                                setViewLocation(window.pageYOffset)
                                e.preventDefault()
                                scrollToTop()
                                setProduct(product)
                              }}
                            >
                              <CardMedia
                                className={classes.media}
                                image={`${restApi}/media/${dbhost}/uploads/invoice/${product.ProductImage}`}
                                title={product.ProductName}
                              />
                            </Link>
                            <CardContent
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                                paddingLeft: 8,
                                paddingRight: 8,
                              }}
                            >
                              <div style={{ minHeight: 90 }}>
                                <Link
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setViewLocation(window.pageYOffset)
                                    scrollToTop()
                                    setProduct(product)
                                  }}
                                >
                                  <h3
                                    style={{ marginTop: 0, marginBottom: 0 }}
                                  >{`${product.ProductName}`}</h3>
                                </Link>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  {`${product.Category}`}
                                </Typography>
                              </div>
                              <div style={{ minHeight: 60 }}>
                                {product.ProductPrices.split(',').map(
                                  (price, i) => {
                                    const priceitem = price.split('|')
                                    return (
                                      <div key={i}>
                                        <span
                                          style={{
                                            fontWeight: 'bold',
                                            fontSize: 16,
                                            display: 'inline-block',
                                            paddingRight: 10,
                                          }}
                                        >
                                          {priceitem[0]}
                                        </span>
                                        {/* 
                                      <br></br>
                                      <span
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 'normal',
                                        }}
                                      >
                                        {priceitem[1]}
                                      </span> */}
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                              <div
                                style={{
                                  textAlign: 'right',
                                  paddingRight: 5,
                                  paddingBottom: 10,
                                }}
                              >
                                {!carts.find(
                                  (a) => a.ProductID == product.id
                                ) && (
                                  <Button
                                    disabled={selectedProductID == product.id}
                                    variant="contained"
                                    disableElevation
                                    size="dense"
                                    color="primary"
                                    disableElevation
                                    style={{
                                      marginRight: 0,
                                    }}
                                    classes={{ root: classes.addRoot }}
                                    onClick={() => {
                                      if (!Cookies.get('signedin')) {
                                        scrollToTop()
                                        history.push('/login')
                                        return
                                      }
                                      setSelectedProductID(product.id)
                                      insertCart({
                                        variables: {
                                          ProductID: product.id,
                                          PriceID: product.PriceID,
                                          Qty: 1,
                                        },
                                      })
                                    }}
                                  >
                                    Add
                                  </Button>
                                )}
                                {carts.find(
                                  (a) => a.ProductID == product.id
                                ) && (
                                  <React.Fragment>
                                    <IconButton
                                      size="medium"
                                      edge={false}
                                      classes={{
                                        root: classes.iconbuttonroot,
                                      }}
                                      onClick={() => {
                                        if (!Cookies.get('signedin')) {
                                          scrollToTop()
                                          history.push('/login')
                                          return
                                        }
                                        if (
                                          carts.find(
                                            (a) => a.ProductID == product.id
                                          ).Qty > 1
                                        ) {
                                          updateCart({
                                            variables: {
                                              ProductID: product.id,
                                              PriceID: product.PriceID,
                                              Qty:
                                                carts.find(
                                                  (a) =>
                                                    a.ProductID == product.id
                                                ).Qty - 1,
                                            },
                                          })
                                        } else {
                                          setSelectedProductID(product.id)
                                          setTimeout(() => {
                                            removeCart({
                                              variables: {
                                                id: product.id,
                                              },
                                            })
                                          }, 1)
                                        }
                                      }}
                                    >
                                      <RemoveCircleOutlineIcon
                                        style={{
                                          color: shopconfig.COLORPRIMARY,
                                          fontSize: 32,
                                        }}
                                      />
                                    </IconButton>
                                    <span
                                      style={{
                                        width: 25,
                                        textAlign: 'center',
                                        display: 'inline-block',
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {
                                        carts.find(
                                          (a) => a.ProductID == product.id
                                        ).Qty
                                      }
                                    </span>
                                    <IconButton
                                      size="medium"
                                      edge={false}
                                      classes={{
                                        root: classes.iconbuttonroot,
                                      }}
                                      onClick={() => {
                                        if (!Cookies.get('signedin')) {
                                          scrollToTop()
                                          history.push('/login')
                                          return
                                        }
                                        updateCart({
                                          variables: {
                                            ProductID: product.id,
                                            PriceID: product.PriceID,
                                            Qty:
                                              carts.find(
                                                (a) => a.ProductID == product.id
                                              ).Qty + 1,
                                          },
                                        })
                                      }}
                                    >
                                      <AddCircleIcon
                                        style={{
                                          color: shopconfig.COLORPRIMARY,
                                          fontSize: 32,
                                        }}
                                      />
                                    </IconButton>
                                  </React.Fragment>
                                )}
                              </div>
                              {/* <div
                              style={{
                                minHeight: carts.find(
                                  (a) => a.ProductID == product.id
                                )
                                  ? 34
                                  : 35,
                                textAlign: 'left',
                              }}
                            >
                              {carts.find((a) => a.ProductID == product.id) && (
                                <h3
                                  style={{
                                    margin: 0,
                                    paddingTop: 5,
                                    
                                  }}
                                >
                                  {
                                    carts.find((a) => a.ProductID == product.id)
                                      .Qty
                                  }{' '}
                                  in trolley
                                </h3>
                              )}
                            </div> */}
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Hidden>
                )}
                {/* {!product && (
                  <div
                    style={{
                      marginTop: 80,
                      marginBottom: 200,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Pagination
                      size="large"
                      variant="outlined"
                      shape="rounded"
                      count={Math.ceil(numberOfProducts.total / 12)}
                      page={currPage}
                      onChange={handleChangePage}
                    />
                  </div>
                )} */}
              </div>

              <Hidden only={['sm', 'xs']}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 0,
                    width: 240,
                    borderLeft: '1px solid #ccc',
                    borderRight: '1px solid #ccc',
                    transform: 'translateZ(0)',
                    position: '-webkit-sticky',
                    position: 'sticky',
                    height: '100vh',
                    margin: 0,
                    padding: 0,
                    top: 0,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      borderBottom: '1px solid #cccccc',
                      padding: 10,
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Total:{' '}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          textAlign: 'right',
                          fontWeight: 'bold',
                          fontSize: 15,
                        }}
                      >
                        RM {parseFloat(totalAmount).toFixed(2)}{' '}
                      </Grid>
                    </Grid>
                    <Divider classes={{ root: classes.dividerroot }} />
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      size="medium"
                      color="primary"
                      component={Link2}
                      to="/checkout"
                      onClick={() => {
                        scrollToTop()
                      }}
                    >
                      Checkout
                    </Button>
                  </div>
                  <List className={classes.listRoot}>
                    <Cart
                      setViewLocation={setViewLocation}
                      setProduct={setProduct}
                      data={carts}
                      setSelectedProductID={setSelectedProductID}
                      removeCart={removeCart}
                    />
                  </List>
                </div>
              </Hidden>
            </div>
          </div>
        </div>

        <ProductDialog
          openProduct={openProduct}
          setOpenProduct={setOpenProduct}
          selectedProductID={selectedProductID}
          setSelectedProductID={setSelectedProductID}
          product={productMobile}
          setProduct={setProductMobile}
        />
      </Container>
    </React.Fragment>
  )
}
