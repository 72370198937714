import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  CardMedia,
  List,
  Link,
} from '@material-ui/core/'

import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import { Link as Link2 } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import ShopDetails from './shopDetails'
import Loading from '../common/loading'
import Menu from './menu'
import { GET_SHOPCONFIG } from '../graphql/config'

const restApi = 'http://frapp.cloud:3501'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

export default function Shop(props) {
  const classes = useStyles()
  const [selectedProductID, setSelectedProductID] = useState()
  const [product, setProduct] = useState()
  const [back, setBack] = useState()
  const [backProductID, setBackProductID] = useState()
  const [currPage, setCurrPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [maxPages, updateMaxPages] = useState(0)
  const [viewLocation, setViewLocation] = useState()
  const [dbhost, setDbhost] = useState(window.location.host.split('.')[0])

  const [totalAmount, setTotalAmount] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const terms = `<div class="fr-view">
  <p>
  <strong>
  Coverage area&nbsp;</strong>
</p>
  <p>
  We cover areas within Klang Valley only.</p>
  <p>
  
</em>
  Please do contact us if your address is out of our coverage area and interested to place order.</u>
</em>
</p>
  <p>

</p>
  <p>
  <strong>
  Delivery time</strong>
</p>
  <p>
  2 - 3 working day from order date.</p>
  <p>
  Our working hours are Monday to Friday, 9am to 5pm. We are closed on Saturday, Sunday and Public Holiday.</p>
  <p>
  <strong>
  Delivery charge </strong>
</p>
  <p>
  RM6.00 to RM15.00 based on location.</p>
  <p>

</p>
  <p>
  <strong>
  Contact details&nbsp;</strong>
</p>
  <p>
  Contact us through <strong>
  Whatsapp at +6012 311 1531</strong>
   or email <strong>
  <a data-fr-linked="true" href="mailto:info@sfnoodles.com">
  info@sfnoodles.com</a>
</strong>
   if you need further assistance.</p>
  <p>

</p>
</div>
`
  return (
    <React.Fragment>
      <Hidden only={['sm', 'xs']}>
        <div style={{ backgroundColor: 'white' }}>
          <Container maxWidth="md">
            <div style={{ minHeight: 100 }} className={classes.toolbar}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  paddingTop: 10,
                }}
              >
                <Link2 to="/">
                  <img
                    style={{ width: 50, verticalAlign: 'middle' }}
                    src={`${restApi}/media/${dbhost}/uploads/logo/${shopconfig.COMLOGO}`}
                  />
                </Link2>
                <h3 style={{ paddingLeft: 10 }}>{shopconfig.COMSHO}</h3>
              </div>
              <Menu value={1} />
            </div>
          </Container>
        </div>
        <Divider />
      </Hidden>
      <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <div className={classes.toolbar}>
          <div className={classes.root}>
            <h2>Terms & Condition</h2>
            <div dangerouslySetInnerHTML={{ __html: terms }} />
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}
