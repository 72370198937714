import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  CardMedia,
  List,
  Link,
} from '@material-ui/core/'

import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import { Link as Link2 } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import Pagination from '@material-ui/lab/Pagination'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Cart from './cart'
import { GET_PRODUCTS, TOTAL_PRODUCTS } from '../graphql/product'
import { GET_SHOPCONFIG } from '../graphql/config'
import {
  GET_CARTS,
  INSERT_CART,
  UPDATE_CART,
  REMOVE_CART,
} from '../graphql/cart'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import ShopDetails from './shopDetails'
import Loading from '../common/loading'
import Menu from './menu'

const restApi = 'http://frapp.cloud:3501'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerroot: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

export default function Shop(props) {
  const classes = useStyles()
  const [selectedProductID, setSelectedProductID] = useState()
  const [product, setProduct] = useState()
  const [back, setBack] = useState()
  const [backProductID, setBackProductID] = useState()
  const [currPage, setCurrPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [maxPages, updateMaxPages] = useState(0)
  const [viewLocation, setViewLocation] = useState()
  const [dbhost, setDbhost] = useState(window.location.host.split('.')[0])

  const [totalAmount, setTotalAmount] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const terms = `<div class="fr-view">
  <p>
  <strong>
  1. Will you deliver my order during MCO? </strong>
</p>
  <p>
  Yes, we have gotten approval from MITI to operate throughout the MCO. Our trucks are able to travel on the road to deliver to your doorsteps.</p>
  <p>
  As requested by the government authorities, all of our trucks are disinfected before and after each batch of deliveries. Also, our drivers are equipped with face mask and hand sanitizer, to ensure they are safe to perform the deliveries.</p>
  <p>
 
</p>
 
  <strong>
  2. When will I receive my order and where do you deliver to? </strong>
</p>
  <p>
  We do <em>
  <strong>
  2-3 days delivery</strong>
</em>
   from Monday to Friday only. Orders made on Friday, Saturday and Sunday will be delivered on the following Monday. Please refer to <em>
  Terms and Conditions</em>
   page for more information.&nbsp;</p>
  <p>
  
</p>
  
</div>
`
  return (
    <React.Fragment>
      <Hidden only={['sm', 'xs']}>
        <div style={{ backgroundColor: 'white' }}>
          <Container maxWidth="md">
            <div style={{ minHeight: 100 }} className={classes.toolbar}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  paddingTop: 10,
                }}
              >
                <Link2 to="/">
                  <img
                    style={{ width: 50, verticalAlign: 'middle' }}
                    src={`${restApi}/media/${dbhost}/uploads/logo/${shopconfig.COMLOGO}`}
                  />
                </Link2>
                <h3 style={{ paddingLeft: 10 }}>{shopconfig.COMSHO}</h3>
              </div>
              <Menu value={2} />
            </div>
          </Container>
        </div>
        <Divider />
      </Hidden>
      <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <div className={classes.toolbar}>
          <div className={classes.root}>
            <h2>FAQ</h2>
            <div dangerouslySetInnerHTML={{ __html: terms }} />
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}
