import React, { useState } from 'react'
import { TextField, Button, Grid } from '@material-ui/core'
import TextFieldDisplay from '../common/textFieldDisplay'
import InputAdornment from '@material-ui/core/InputAdornment'
import PhoneIcon from '@material-ui/icons/Phone'
import PrintIcon from '@material-ui/icons/Print'

const CustomerDisplay = props => {
  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextFieldDisplay
          label="Customer Code"
          value={props.data.CustomerCode}
        />
        <TextFieldDisplay label="No Of Carton" value={props.data.NoOfCarton} />
        <TextFieldDisplay
          multiline
          label="Remarks"
          value={props.data.Remarks}
        />
      </Grid>
      <Grid item md={6} xs={12}></Grid>
    </Grid>
  )
}

export default CustomerDisplay
